import api from "@/core/services/api.service";

export default {
  field: [
    "url",
    "name",
    "description",
    "frequency",
    "territorial_jurisdiction",
    "nation_of_interest",
    "active",
    "changedetection_watch",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
    "include_filters",
    "subtractive_selectors",
    "extract_text",
    "ignore_text",
    "trigger_text",
    "text_should_not_be_present",
    "language",
    "administrative_structures",
    "categories",
    "assignees"
  ],

  get PATH() {
    return "post-sources";
  },

  async getAll() {
    const res = await api.get(`${this.PATH}/`);
    return res.data;
  },

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  },

  async update(id, detail) {
    let info = {}
    Object.keys(detail).forEach(key => {
      if (this.field.includes(key)) {
        info[key] = detail[key]
      }
    });
    const res = await api.patch(`${this.PATH}/${id}/`, info);
    return res.data
  }
};