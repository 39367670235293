var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Source information")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v("Update source information")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          attrs: {
            "type": "reset"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v("Save Changes")]), _c('button', {
          staticClass: "btn btn-secondary",
          attrs: {
            "type": "reset"
          }
        }, [_vm._v("Cancel")])])]), _c('div', {
          staticClass: "card-body"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Nome'),
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Nome'), "*"),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Giurisdizione territoriale'),
            "vid": "territorial_jurisdiction",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-select', {
                attrs: {
                  "options": _vm.getTerritorialJurisdiction,
                  "label": "".concat(_vm.$t('Giurisdizione territoriale'), "*"),
                  "multiple": "",
                  "searchable": "",
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.territorial_jurisdiction,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "territorial_jurisdiction", $$v);
                  },
                  expression: "form.territorial_jurisdiction"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Struttura amministrativa'),
            "vid": "administrative_structures",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-select', {
                attrs: {
                  "options": _vm.getAdministrativeStructure,
                  "label": "".concat(_vm.$t('Struttura amministrativa'), "*"),
                  "multiple": "",
                  "searchable": "",
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.administrative_structures,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "administrative_structures", $$v);
                  },
                  expression: "form.administrative_structures"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Nazione di interesse'),
            "vid": "nation_of_interest",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-select', {
                attrs: {
                  "options": _vm.getCountries,
                  "label": "".concat(_vm.$t('Nazione di interesse'), "*"),
                  "searchable": "",
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.nation_of_interest,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "nation_of_interest", $$v);
                  },
                  expression: "form.nation_of_interest"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Stato'),
            "vid": "active",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('euro-radio', {
                attrs: {
                  "options": _vm.status,
                  "horizontal": "",
                  "label": "".concat(_vm.$t('Stato'), "*"),
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "active", $$v);
                  },
                  expression: "form.active"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Descrizione'),
            "vid": "description"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "horizontal": "",
                  "label": "".concat(_vm.$t('Descrizione')),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "description", $$v);
                  },
                  expression: "form.description"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Categorie'),
            "vid": "categories",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('euro-select', {
                attrs: {
                  "options": _vm.getCategories,
                  "label": "".concat(_vm.$t('Categorie'), "*"),
                  "horizontal": "",
                  "multiple": "",
                  "searchable": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.categories,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "categories", $$v);
                  },
                  expression: "form.categories"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Tags'),
            "vid": "tags"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('euro-input', {
                attrs: {
                  "horizontal": "",
                  "label": "".concat(_vm.$t('Tags')),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.tags,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "tags", $$v);
                  },
                  expression: "form.tags"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Assegnatari'),
            "vid": "assignees",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('euro-select', {
                attrs: {
                  "horizontal": "",
                  "options": _vm.getUsers,
                  "label": "".concat(_vm.$t('Assegnatari'), "*"),
                  "multiple": "",
                  "searchable": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.assignees,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "assignees", $$v);
                  },
                  expression: "form.assignees"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Frequenza'),
            "vid": "frequency",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('euro-select', {
                attrs: {
                  "options": _vm.frequencies,
                  "horizontal": "",
                  "label": "".concat(_vm.$t('Frequenza'), "*"),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.frequency,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "frequency", $$v);
                  },
                  expression: "form.frequency"
                }
              })];
            }
          }], null, true)
        }), _vm._v(" // TODO: SelectLINGUA "), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Lingua'),
            "vid": "language",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('euro-input', {
                attrs: {
                  "horizontal": "",
                  "label": "".concat(_vm.$t('Lingua'), "*"),
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.language,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "language", $$v);
                  },
                  expression: "form.language"
                }
              })];
            }
          }], null, true)
        }), _vm._v(" // TODO: Url validation "), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Url'),
            "vid": "url",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Url'), "*"),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.url,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "url", $$v);
                  },
                  expression: "form.url"
                }
              })];
            }
          }], null, true)
        }), _c('h5', {
          staticClass: "font-weight-bold mb-6 mt-6"
        }, [_vm._v("Time Between Check")]), _c('div', {
          staticClass: "d-flex flex-row"
        }, [_c('div', [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Settimane'),
            "vid": "weeks"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Settimane')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.weeks,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "weeks", $$v);
                  },
                  expression: "form.weeks"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "ml-8"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Giorni'),
            "vid": "days"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Giorni')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.days,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "days", $$v);
                  },
                  expression: "form.days"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "ml-8"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Ore'),
            "vid": "hours"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var errors = _ref16.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Ore')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.hours,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "hours", $$v);
                  },
                  expression: "form.hours"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "ml-8"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Minuti'),
            "vid": "minutes"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref17) {
              var errors = _ref17.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Minuti')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.minutes,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "minutes", $$v);
                  },
                  expression: "form.minutes"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "ml-8"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Secondi'),
            "vid": "seconds"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref18) {
              var errors = _ref18.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Secondi')),
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.seconds,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "seconds", $$v);
                  },
                  expression: "form.seconds"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('h5', {
          staticClass: "font-weight-bold mb-6 mt-6"
        }, [_vm._v("Filters & Triggers")]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('CSS/JSONPATH/JQ/XPATH FILTERS'),
            "vid": "include_filters"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref19) {
              var errors = _ref19.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('CSS/JSONPATH/JQ/XPATH FILTERS')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.include_filters
                },
                model: {
                  value: _vm.form.include_filters,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "include_filters", $$v);
                  },
                  expression: "form.include_filters"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_vm._v("One rule per line, "), _c('i', [_vm._v("any")]), _vm._v(" rules that matches will be used."), _c('br'), _c('ul', [_c('li', [_vm._v("CSS - Limit text to this CSS rule, only text matching this CSS rule is included.")]), _c('li', [_vm._v("JSON - Limit text to this JSON rule, using either "), _c('a', {
          attrs: {
            "href": "https://pypi.org/project/jsonpath-ng/",
            "target": "new"
          }
        }, [_vm._v("JSONPath")]), _vm._v(" or "), _c('a', {
          attrs: {
            "href": "https://stedolan.github.io/jq/",
            "target": "new"
          }
        }, [_vm._v("jq")]), _vm._v(" (if installed). "), _c('ul', [_c('li', [_vm._v("JSONPath: Prefix with "), _c('code', [_vm._v("json:")]), _vm._v(", use "), _c('code', [_vm._v("json:$")]), _vm._v(" to force re-formatting if required, "), _c('a', {
          attrs: {
            "href": "https://jsonpath.com/",
            "target": "new"
          }
        }, [_vm._v("test your JSONPath here")]), _vm._v(".")]), _c('li', [_vm._v("jq: Prefix with "), _c('code', [_vm._v("jq:")]), _vm._v(" and "), _c('a', {
          attrs: {
            "href": "https://jqplay.org/",
            "target": "new"
          }
        }, [_vm._v("test your jq here")]), _vm._v(". Using "), _c('a', {
          attrs: {
            "href": "https://stedolan.github.io/jq/",
            "target": "new"
          }
        }, [_vm._v("jq")]), _vm._v(" allows for complex filtering and processing of JSON data with built-in functions, regex, filtering, and more. See examples and documentation "), _c('a', {
          attrs: {
            "href": "https://stedolan.github.io/jq/manual/",
            "target": "new"
          }
        }, [_vm._v("here")]), _vm._v(".")])])]), _c('li', [_vm._v("XPath - Limit text to this XPath rule, simply start with a forward-slash, "), _c('ul', [_c('li', [_vm._v("Example: "), _c('code', [_vm._v("//*[contains(@class, 'sametext')]")]), _vm._v(" or "), _c('code', [_vm._v("xpath://*[contains(@class, 'sametext')]")]), _vm._v(", "), _c('a', {
          attrs: {
            "href": "http://xpather.com/",
            "target": "new"
          }
        }, [_vm._v("test your XPath here")])]), _c('li', [_vm._v("Example: Get all titles from an RSS feed "), _c('code', [_vm._v("//title/text()")])])])])]), _vm._v(" Please be sure that you thoroughly understand how to write CSS, JSONPath, XPath, or jq selector rules before filing an issue on GitHub! "), _c('a', {
          attrs: {
            "href": "https://github.com/dgtlmoon/changedetection.io/wiki/CSS-Selector-help"
          }
        }, [_vm._v("here for more CSS selector help")]), _vm._v("."), _c('br')]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('REMOVE ELEMENTS'),
            "vid": "subtractive_selectors"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref20) {
              var errors = _ref20.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('REMOVE ELEMENTS')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.subtractive_selectors
                },
                model: {
                  value: _vm.form.subtractive_selectors,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "subtractive_selectors", $$v);
                  },
                  expression: "form.subtractive_selectors"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_c('ul', [_c('li', [_vm._v(" Remove HTML element(s) by CSS selector before text conversion. ")]), _c('li', [_vm._v(" Add multiple elements or CSS selectors per line to ignore multiple parts of the HTML. ")])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('TRIGGER/WAIT FOR TEXT'),
            "vid": "trigger_text"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref21) {
              var errors = _ref21.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('TRIGGER/WAIT FOR TEXT')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.trigger_text
                },
                model: {
                  value: _vm.form.trigger_text,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "trigger_text", $$v);
                  },
                  expression: "form.trigger_text"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_c('ul', [_c('li', [_vm._v("Text to wait for before triggering a change/notification, all text and regex are tested "), _c('i', [_vm._v("case-insensitive")]), _vm._v(". ")]), _c('li', [_vm._v("Trigger text is processed from the result-text that comes out of any CSS/JSON Filters for this watch ")]), _c('li', [_vm._v("Each line is processed separately (think of each line as \"OR\")")]), _c('li', [_vm._v("Note: Wrap in forward slash / to use regex example: "), _c('code', [_vm._v("/foo\\d/")])])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('IGNORE TEXT'),
            "vid": "ignore_text"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref22) {
              var errors = _ref22.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('IGNORE TEXT')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.ignore_text
                },
                model: {
                  value: _vm.form.ignore_text,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "ignore_text", $$v);
                  },
                  expression: "form.ignore_text"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_c('ul', [_c('li', [_vm._v("Each line processed separately, any line matching will be ignored (removed before creating the checksum) ")]), _c('li', [_vm._v("Regular Expression support, wrap the entire line in forward slash "), _c('code', [_vm._v("/regex/")])]), _c('li', [_vm._v("Changing this will affect the comparison checksum which may trigger an alert")]), _c('li', [_vm._v("Use the preview/show current tab to see ignores")])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('BLOCK CHANGE-DETECTION IF TEXT MATCHES'),
            "vid": "text_should_not_be_present"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref23) {
              var errors = _ref23.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('BLOCK CHANGE-DETECTION IF TEXT MATCHES')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.text_should_not_be_present
                },
                model: {
                  value: _vm.form.text_should_not_be_present,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "text_should_not_be_present", $$v);
                  },
                  expression: "form.text_should_not_be_present"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_c('ul', [_c('li', [_vm._v("Block change-detection while this text is on the page, all text and regex are tested "), _c('i', [_vm._v("case-insensitive")]), _vm._v(", good for waiting for when a product is available again ")]), _c('li', [_vm._v("Block text is processed from the result-text that comes out of any CSS/JSON Filters for this watch")]), _c('li', [_vm._v("All lines here must not exist (think of each line as \"OR\")")]), _c('li', [_vm._v("Note: Wrap in forward slash / to use regex example: "), _c('code', [_vm._v("/foo\\d/")])])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('EXTRACT TEXT'),
            "vid": "extract_text"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref24) {
              var errors = _ref24.errors;
              return [_c('euro-text-area', {
                attrs: {
                  "label": "".concat(_vm.$t('EXTRACT TEXT')),
                  "error-messages": errors,
                  "placeholder": _vm.placeholders.extract_text
                },
                model: {
                  value: _vm.form.extract_text,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "extract_text", $$v);
                  },
                  expression: "form.extract_text"
                }
              })];
            }
          }], null, true)
        }), _c('span', {
          staticClass: "pure-form-message-inline"
        }, [_c('ul', [_c('li', [_vm._v("Extracts text in the final output (line by line) after other filters using regular expressions; "), _c('ul', [_c('li', [_vm._v("Regular expression ‐ example "), _c('code', [_vm._v("/reports.+?2022/i")])]), _c('li', [_vm._v("Use "), _c('code', [_vm._v("//(?aiLmsux))")]), _vm._v(" type flags (more "), _c('a', {
          attrs: {
            "href": "https://docs.python.org/3/library/re.html#index-15"
          }
        }, [_vm._v("information here")]), _vm._v(")"), _c('br')]), _c('li', [_vm._v("Keyword example ‐ example "), _c('code', [_vm._v("Out of stock")])]), _c('li', [_vm._v("Use groups to extract just that text ‐ example "), _c('code', [_vm._v("/reports.+?(\\d+)/i")]), _vm._v(" returns a list of years only")])])]), _c('li', [_vm._v("One line per regular-expression/ string match")])])]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('CHANGEDETECTION WATCH'),
            "vid": "changedetection_watch"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref25) {
              var errors = _ref25.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('CHANGEDETECTION WATCH')),
                  "horizontal": "",
                  "error-messages": errors,
                  "disabled": true
                },
                model: {
                  value: _vm.form.changedetection_watch,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "changedetection_watch", $$v);
                  },
                  expression: "form.changedetection_watch"
                }
              })];
            }
          }], null, true)
        })], 1)])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }