<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Source information") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">Update source information</span>
        </div>
        <div class="card-toolbar">
          <button type="reset" class="btn btn-success mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
            @click.prevent="handleSubmit(save)">Save Changes</button>
          <button type="reset" class="btn btn-secondary">Cancel</button>
        </div>
      </div>
      <div class="card-body">
        <validation-provider v-slot="{ errors }" :name="$t('Nome')" vid="name" rules="required">
          <euro-input v-model="form.name" :label="`${$t('Nome')}*`" horizontal :error-messages="errors"></euro-input>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Giurisdizione territoriale')" vid="territorial_jurisdiction"
          rules="required">
          <euro-select v-model="form.territorial_jurisdiction" :options="getTerritorialJurisdiction"
            :label="`${$t('Giurisdizione territoriale')}*`" multiple searchable horizontal
            :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Struttura amministrativa')" vid="administrative_structures"
          rules="required">
          <euro-select v-model="form.administrative_structures" :options="getAdministrativeStructure"
            :label="`${$t('Struttura amministrativa')}*`" multiple searchable horizontal
            :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Nazione di interesse')" vid="nation_of_interest"
          rules="required">
          <euro-select v-model="form.nation_of_interest" :options="getCountries" :label="`${$t('Nazione di interesse')}*`"
            searchable horizontal :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Stato')" vid="active" rules="required">
          <euro-radio v-model="form.active" :options="status" horizontal :label="`${$t('Stato')}*`"
            :error-messages="errors" size="lg"></euro-radio>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Descrizione')" vid="description">
          <euro-text-area v-model="form.description" horizontal :label="`${$t('Descrizione')}`"
            :error-messages="errors"></euro-text-area>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Categorie')" vid="categories" rules="required">
          <euro-select v-model="form.categories" :options="getCategories" :label="`${$t('Categorie')}*`" horizontal
            multiple searchable :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Tags')" vid="tags">
          <euro-input v-model="form.tags" horizontal :label="`${$t('Tags')}`" :error-messages="errors"></euro-input>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Assegnatari')" vid="assignees" rules="required">
          <euro-select v-model="form.assignees" horizontal :options="getUsers" :label="`${$t('Assegnatari')}*`" multiple
            searchable :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Frequenza')" vid="frequency" rules="required">
          <euro-select v-model="form.frequency" :options="frequencies" horizontal :label="`${$t('Frequenza')}*`"
            :error-messages="errors"></euro-select>
        </validation-provider>

        // TODO: SelectLINGUA
        <validation-provider v-slot="{ errors }" :name="$t('Lingua')" vid="language" rules="required">
          <euro-input v-model="form.language" horizontal :label="`${$t('Lingua')}*`"
            :error-messages="errors"></euro-input>
        </validation-provider>

        // TODO: Url validation
        <validation-provider v-slot="{ errors }" :name="$t('Url')" vid="url" rules="required">
          <euro-input v-model="form.url" :label="`${$t('Url')}*`" horizontal :error-messages="errors"></euro-input>
        </validation-provider>

        <h5 class="font-weight-bold mb-6 mt-6">Time Between Check</h5>
        <div class="d-flex flex-row">
          <div>
            <validation-provider v-slot="{ errors }" :name="$t('Settimane')" vid="weeks">
              <euro-input v-model="form.weeks" :label="`${$t('Settimane')}`" horizontal
                :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
          <div class="ml-8">
            <validation-provider v-slot="{ errors }" :name="$t('Giorni')" vid="days">
              <euro-input v-model="form.days" :label="`${$t('Giorni')}`" horizontal :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
          <div class="ml-8">
            <validation-provider v-slot="{ errors }" :name="$t('Ore')" vid="hours">
              <euro-input v-model="form.hours" :label="`${$t('Ore')}`" horizontal :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
          <div class="ml-8">
            <validation-provider v-slot="{ errors }" :name="$t('Minuti')" vid="minutes">
              <euro-input v-model="form.minutes" :label="`${$t('Minuti')}`" horizontal
                :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
          <div class="ml-8">
            <validation-provider v-slot="{ errors }" :name="$t('Secondi')" vid="seconds">
              <euro-input v-model="form.seconds" :label="`${$t('Secondi')}`" horizontal
                :error-messages="errors"></euro-input>
            </validation-provider>
          </div>
        </div>

        <h5 class="font-weight-bold mb-6 mt-6">Filters &amp; Triggers</h5>

        <validation-provider v-slot="{ errors }" :name="$t('CSS/JSONPATH/JQ/XPATH FILTERS')" vid="include_filters">
          <euro-text-area v-model="form.include_filters" :label="`${$t('CSS/JSONPATH/JQ/XPATH FILTERS')}`"
            :error-messages="errors" :placeholder="placeholders.include_filters"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">One rule per line, <i>any</i> rules that matches will be used.<br>
          <ul>
            <li>CSS - Limit text to this CSS rule, only text matching this CSS rule is included.</li>
            <li>JSON - Limit text to this JSON rule, using either <a href="https://pypi.org/project/jsonpath-ng/"
                target="new">JSONPath</a> or <a href="https://stedolan.github.io/jq/" target="new">jq</a> (if
              installed).
              <ul>
                <li>JSONPath: Prefix with <code>json:</code>, use <code>json:$</code> to force re-formatting if
                  required, <a href="https://jsonpath.com/" target="new">test your JSONPath here</a>.</li>

                <li>jq: Prefix with <code>jq:</code> and <a href="https://jqplay.org/" target="new">test your jq
                    here</a>. Using <a href="https://stedolan.github.io/jq/" target="new">jq</a> allows for complex
                  filtering and processing of JSON data with built-in functions, regex, filtering, and more. See
                  examples and documentation <a href="https://stedolan.github.io/jq/manual/" target="new">here</a>.</li>

              </ul>
            </li>
            <li>XPath - Limit text to this XPath rule, simply start with a forward-slash,
              <ul>
                <li>Example: <code>//*[contains(@class, 'sametext')]</code> or
                  <code>xpath://*[contains(@class, 'sametext')]</code>, <a href="http://xpather.com/" target="new">test
                    your XPath here</a>
                </li>
                <li>Example: Get all titles from an RSS feed <code>//title/text()</code></li>
              </ul>
            </li>
          </ul>
          Please be sure that you thoroughly understand how to write CSS, JSONPath, XPath, or jq selector rules before
          filing an issue on GitHub! <a href="https://github.com/dgtlmoon/changedetection.io/wiki/CSS-Selector-help">here
            for more CSS selector
            help</a>.<br>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('REMOVE ELEMENTS')" vid="subtractive_selectors">
          <euro-text-area v-model="form.subtractive_selectors" :label="`${$t('REMOVE ELEMENTS')}`"
            :error-messages="errors" :placeholder="placeholders.subtractive_selectors"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">
          <ul>
            <li> Remove HTML element(s) by CSS selector before text conversion. </li>
            <li> Add multiple elements or CSS selectors per line to ignore multiple parts of the HTML. </li>
          </ul>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('TRIGGER/WAIT FOR TEXT')" vid="trigger_text">
          <euro-text-area v-model="form.trigger_text" :label="`${$t('TRIGGER/WAIT FOR TEXT')}`" :error-messages="errors"
            :placeholder="placeholders.trigger_text"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">
          <ul>
            <li>Text to wait for before triggering a change/notification, all text and regex are tested
              <i>case-insensitive</i>.
            </li>
            <li>Trigger text is processed from the result-text that comes out of any CSS/JSON Filters for this watch
            </li>
            <li>Each line is processed separately (think of each line as "OR")</li>
            <li>Note: Wrap in forward slash / to use regex example: <code>/foo\d/</code></li>
          </ul>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('IGNORE TEXT')" vid="ignore_text">
          <euro-text-area v-model="form.ignore_text" :label="`${$t('IGNORE TEXT')}`" :error-messages="errors"
            :placeholder="placeholders.ignore_text"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">
          <ul>
            <li>Each line processed separately, any line matching will be ignored (removed before creating the checksum)
            </li>
            <li>Regular Expression support, wrap the entire line in forward slash <code>/regex/</code></li>
            <li>Changing this will affect the comparison checksum which may trigger an alert</li>
            <li>Use the preview/show current tab to see ignores</li>
          </ul>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('BLOCK CHANGE-DETECTION IF TEXT MATCHES')"
          vid="text_should_not_be_present">
          <euro-text-area v-model="form.text_should_not_be_present"
            :label="`${$t('BLOCK CHANGE-DETECTION IF TEXT MATCHES')}`" :error-messages="errors"
            :placeholder="placeholders.text_should_not_be_present"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">
          <ul>
            <li>Block change-detection while this text is on the page, all text and regex are tested
              <i>case-insensitive</i>, good for waiting for when a product is available again
            </li>
            <li>Block text is processed from the result-text that comes out of any CSS/JSON Filters for this watch</li>
            <li>All lines here must not exist (think of each line as "OR")</li>
            <li>Note: Wrap in forward slash / to use regex example: <code>/foo\d/</code></li>
          </ul>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('EXTRACT TEXT')" vid="extract_text">
          <euro-text-area v-model="form.extract_text" :label="`${$t('EXTRACT TEXT')}`" :error-messages="errors"
            :placeholder="placeholders.extract_text"></euro-text-area>
        </validation-provider>
        <span class="pure-form-message-inline">
          <ul>
            <li>Extracts text in the final output (line by line) after other filters using regular expressions;
              <ul>
                <li>Regular expression ‐ example <code>/reports.+?2022/i</code></li>
                <li>Use <code>//(?aiLmsux))</code> type flags (more <a
                    href="https://docs.python.org/3/library/re.html#index-15">information here</a>)<br></li>
                <li>Keyword example ‐ example <code>Out of stock</code></li>
                <li>Use groups to extract just that text ‐ example <code>/reports.+?(\d+)/i</code> returns a list of
                  years only</li>
              </ul>
            </li>
            <li>One line per regular-expression/ string match</li>
          </ul>
        </span>

        <validation-provider v-slot="{ errors }" :name="$t('CHANGEDETECTION WATCH')" vid="changedetection_watch">
          <euro-input v-model="form.changedetection_watch" :label="`${$t('CHANGEDETECTION WATCH')}`" horizontal
            :error-messages="errors" :disabled="true"></euro-input>
        </validation-provider>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UsersService from "@/core/services/users.service";
import icons from "@/core/config/icons";
import { mapGetters, mapState, mapActions } from "vuex";
import SourceService from "@/core/services/post/source.service";
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';
export default {
  data() {
    return {
      placeholders: {
        include_filters: "#example \nxpath://body/div/span[contains(@class, 'example-class')]",
        subtractive_selectors: "header\nfooter\nnav\n.stockticker",
        trigger_text: "Some text to wait for in a line\n/some.regex\\d{2}/ for case-INsensitive regex",
        ignore_text: "Some text to ignore in a line\n/some.regex\\d{2}/ for case-INsensitive regex",
        text_should_not_be_present: "For example: Out of stock\nSold out\nNot in stock\nUnavailable",
        extract_text: "\\d+ online"
      },
      isSaving: false,
      icons,
      source: null,
      users: [],
      status: [
        {
          value: true,
          text: 'Attivo'
        },
        {
          value: false,
          text: 'Disattivo'
        }
      ],
      frequencies: [
        {
          value: '',
          text: 'Seleziona'
        },
        {
          value: '30g',
          text: '30 Giorni'
        },
        {
          value: '07g',
          text: '7 Giorni'
        },
        {
          value: '05g',
          text: '5 Giorni'
        },
        {
          value: '01g',
          text: '1 Giorno'
        },
        {
          value: '12h',
          text: '12 Ore'
        },
        {
          value: '06h',
          text: '6 Ore'
        },
        {
          value: '03h',
          text: '3 Ore'
        },
        {
          value: '01h',
          text: '1 Ora'
        },
        {
          value: '30m',
          text: '30 Minuti'
        },
        {
          value: '20m',
          text: '20 Minuti'
        },
        {
          value: '10m',
          text: '10 Minuti'
        },
        {
          value: '05m',
          text: '5 Minuti'
        },
      ],
      form: {
        territorial_jurisdiction: [],
        administrative_structures: [],
        nation_of_interest: null,
        name: null,
        url: null,
        description: null,
        categories: [],
        tags: null,
        assignees: null,
        frequency: null,
        language: null,
        active: null,
        weeks: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
        include_filters: null,
        subtractive_selectors: null,
        extract_text: null,
        ignore_text: null,
        trigger_text: null,
        text_should_not_be_present: null,
        changedetection_watch: null
      }
    };
  },

  computed: {
    ...mapState("source", ["sources"]),
    ...mapGetters("constants", ["getCountries"]),
    ...mapGetters("npg", ["getTerritorialJurisdiction", "getAdministrativeStructure", "getCategories"]),
    sourceID() {
      return this.$route.params.sourceID;
    },
    getUsers() {
      return this.users?.map(el => ({
        text: el.email,
        value: el.id,
      }));
    }
  },
  mounted() {
    this.loadSource()
    this.loadCategories()
    this.loadAdministrativeStructures()
    this.loadTerritorialJurisdictions()

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Pubblications") },
      { title: this.$t("Source") },
      { title: this.form.name },
      { title: this.$t("Overview") }
    ]);
  },
  methods: {
    ...mapActions("source", ["loadSources", "setSourceId"]),
    ...mapActions("npg", ["loadAdministrativeStructures", "loadTerritorialJurisdictions", "loadCategories"]),
    loadSource() {
      this.source = this.sources.find(element => element.id == this.sourceID);
      this.formatInfo(this.source)
      UsersService.getApStaffUsers({
        perPage: 1000000,
        page: 1,
        sortBy: null,
        sortDesc: null,
        search: null,
      })
        .then(res => {
          this.users = res.results;
        })
        .catch(() => this.users = []);
    },
    formatInfo(res) {
      this.form.name = res.name
      this.form.url = res.url
      this.form.active = res.active
      this.form.description = res.description
      this.form.territorial_jurisdiction = res.territorial_jurisdiction
      this.form.administrative_structures = res.administrative_structures
      this.form.nation_of_interest = res.nation_of_interest
      this.form.categories = res.categories
      this.form.assignees = res.assignees
      this.form.frequency = res.frequency
      this.form.language = res.language
      this.form.weeks = res.weeks
      this.form.days = res.days
      this.form.hours = res.hours
      this.form.minutes = res.minutes
      this.form.seconds = res.seconds
      this.form.include_filters = res.include_filters
      this.form.subtractive_selectors = res.subtractive_selectors
      this.form.extract_text = res.extract_text
      this.form.ignore_text = res.ignore_text
      this.form.trigger_text = res.trigger_text
      this.form.text_should_not_be_present = res.text_should_not_be_present
      this.form.changedetection_watch = res.changedetection_watch
    },
    async save() {
      this.isSaving = true;
      try {
        const source = await SourceService.update(this.sourceID, this.form)
        this.setSourceId(source)
        successToast(this.$t("Source information saved"));
        this.$emit("updated");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail ?? this.$t("Error!!! Source information not saved"));
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>
.pure-form-message-inline {
  color: #777;
  vertical-align: middle;
  font-size: .875em;
  padding-bottom: 2rem;
  display: block;
}

.pure-form-message-inline ul {
  padding-left: 1em;
  padding-top: 0px;
  margin-top: 4px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1rem;
}

.pure-form-message-inline ul ul {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
</style>
